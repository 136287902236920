<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <logo-text />
        </b-link>
        <div v-if="isLoggedIn && fromLink" class="text-center w-100 py-3">
          <b-card-title class="mb-1"> Account koppelen </b-card-title>
          <b-avatar
            size="200px"
            variant="light-primary"
            :text="getInitials($store.state.AppActiveUser.displayName)"
            :src="
              $store.state.AppActiveUser.profilePic
                ? $store.state.AppActiveUser.profilePic.large
                : ''
            "
          />
          <p class="mt-3">Je bent aangemeld als</p>
          <h1>{{ $store.state.AppActiveUser.displayName }}</h1>
          <b-button
            variant="primary"
            class="mt-2 mb-50"
            @click="linkUser"
            :disabled="loading"
            ><b-spinner v-if="loading" />Koppelen met dit account</b-button
          >
          <b-button
            variant="outline-primary"
            class="my-50"
            @click="changeAccount"
            :disabled="loading"
            >Ander account</b-button
          >
        </div>
        <div v-else-if="isLoggedIn && !fromLink">
          <b-card-title class="mb-1"> Account koppelen </b-card-title>
          <b-card-text class="mb-2">
            Geef de code in die u van uw organisatie ontvangen heeft.
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="submitForm"
          >
            <!-- email -->
            <b-form-group label="Code" label-for="code">
              <b-form-input
                id="code"
                v-model="code"
                :disabled="loading"
                :class="$v.code.$error ? 'is-invalid' : ''"
                name="code"
                placeholder="******"
              />
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit" :disabled="loading">
              <b-spinner v-if="loading" small></b-spinner>
              Koppelen
            </b-button>
          </b-form>
        </div>
        <div v-else-if="!isLoggedIn && fromLink" class="text-center w-100 py-3">
          <b-card-title class="mb-1"> Account koppelen </b-card-title>

          <p class="mt-3">Je moet aangemeld zijn om je account te koppelen</p>
          <b-button
            variant="primary"
            class="mt-2 mb-50 w-100"
            :to="{ name: 'auth-login', query: { to: $route.fullPath } }"
            :disabled="loading"
            >Aanmelden</b-button
          >
          <b-button
            variant="outline-primary"
            class="my-50  w-100"
            :to="{ name: 'auth-register', query: { to: $route.fullPath, code: code } }"
            :disabled="loading"
            >Registreren</b-button
          >
        </div>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import LogoText from "@core/layouts/components/LogoText.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner,
  BAvatar,
} from "bootstrap-vue";
import { functions, auth } from "@/firebase/firebaseConfig";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import mainMixin from "@/mixins/mainMixin";
export default {
  name: "LinkAccount",
  mixins: [mainMixin],
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    LogoText,
    BAvatar,
  },
  data() {
    return {
      code: "",
      fromLink: false,
      done: false,
      loading: false,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.fromLink = true;
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.AppActiveUser != null;
    },
  },
  methods: {
    linkUser() {
      this.loading = true;
      functions
        .httpsCallable("checkVerificationCode")({
          code: this.code,
          uid: auth.currentUser.uid,
        })
        .then((res) => {
          if (res.data != false) {
            this.done = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Gelukt!",
                text: "Je account werd gekoppeld aan je organisatie.",
                icon: "CheckICon",
                variant: "success",
              },
            });
            var getUrl = window.location;
            var baseUrl = getUrl.protocol + "//" + getUrl.host;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error!",
                icon: "AlertCirleIcon",
                variant: "danger",
              },
            });
          }

         
          return this.$store.dispatch('auth/loadUser', {result: {user: {uid: this.$store.state.AppActiveUser.uid}}})
          //this.$router.back();
        }).then(() => {
             this.loading = false;
             this.$router.push({name: 'home'});
        })
        .catch((error) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              text: error.message,
              icon: "AlertCirleIcon",
              variant: "danger",
            },
          });
        });
    },
    changeAccount() {
      this.$store.dispatch("auth/signOut", false).then(() => {
          window.location = this.$route.fullPath;
      })
    },
    submitForm() {
      this.fromLink = false;
      this.error = null;
      if (!this.$v.$invalid) {
        this.linkUser();
      } else {
        this.$v.$touch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulier incorrect!",
            icon: "AlertCirleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
